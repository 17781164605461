import { deepFreeze } from "@/utils/structure";

export const HOME_OG_IMAGE_URL =
  "https://cdn.sanity.io/images/bm4duuk0/production/d79a1f8ce04ae86ccc1ecdae494598c96538683b-1200x630.png";

// taking from https://stripe.com/docs/api/payment_methods/object#payment_method_object-type
export const PAYMENT_METHODS = deepFreeze({
  PAYNOW: "Stripe PayNow",
  CARD: "Stripe Card",
});
export const STRIPE_CHECKOUT_SESSION_VERSIONS = {
  V2: "v2",
};
export const CARD_SURCHARGE_PERCENTAGE = 3;
export const PICKUP_FEE = 8;
export const MIN_CHEAPER_SHIPMENT_QTY = 3;
export const MAX_SHIPMENTS_PER_CHECKOUT = 80;
export const MAX_SHIPMENTS_PER_TOPUP = 50;

export const STANDARD_SHIPPING_CONSUMER_SURCHARGE_SGD = 12.5;
export const RURAL_SURCHARGE_SGD = 33.6;
export const DIRECT_SIGNATURE_CHARGE_SGD = 7;
export const INSURANCE_CHARGE_PERCENTAGE = 2.4;

export const STANDARD_SHIPPING_ENABLED =
  process.env.NEXT_PUBLIC_STANDARD_SHIPPING_ENABLED === "1" || false;
export const ECOMMERCE_STANDARD_SHIPPING_ENABLED =
  process.env.NEXT_PUBLIC_ECOMMERCE_STANDARD_SHIPPING_ENABLED === "1" || false;
export const ECOMMERCE_IOSS_ENABLED =
  process.env.NEXT_PUBLIC_ECOMMERCE_IOSS_ENABLED === "1" || false;
export const SHIPPING_METHODS = deepFreeze({
  POSTAL: {
    label: "Postal Shipping",
    value: "POSTAL",
    duration: "8-14 working days",
  },
  STANDARD: {
    label: "Standard Shipping",
    value: "STANDARD",
    duration: "8-14 working days",
  },
  EXPRESS: {
    label: "Express Shipping",
    value: "EXPRESS",
    duration: "2-5 working days",
  },
});

export const BULK_UPLOAD_ECOMMERCE_ENABLED =
  process.env.NEXT_PUBLIC_BULK_UPLOAD_ECOMMERCE_ENABLED === "1" || false;

export const USER_READ_DATA_FROM_POSTGRESQL =
  process.env.USER_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const SHIPMENT_READ_DATA_FROM_POSTGRESQL =
  process.env.SHIPMENT_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const CART_READ_DATA_FROM_POSTGRESQL =
  process.env.CART_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const PRICING_READ_DATA_FROM_POSTGRESQL =
  process.env.PRICING_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const PAYMENT_READ_DATA_FROM_POSTGRESQL =
  process.env.PAYMENT_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const WHATSAPP_BUSINESS_READ_DATA_FROM_POSTGRESQL =
  process.env.WHATSAPP_BUSINESS_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const TRACKING_READ_DATA_FROM_POSTGRESQL =
  process.env.TRACKING_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const GIFT_CARD_READ_DATA_FROM_POSTGRESQL =
  process.env.GIFT_CARD_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const NEXT_PUBLIC_STANDARD_SHIPMENT_DOWNLOAD_LABEL =
  process.env.NEXT_PUBLIC_STANDARD_SHIPMENT_DOWNLOAD_LABEL === "1" || false;
export const POSTAL_SHIPPING_ENABLED =
  process.env.NEXT_PUBLIC_POSTAL_SHIPPING_ENABLED === "1" || false;

export const DEFAULT_COUNTRY_CALL_CODE = "SG/+65";

export const OPTIMIZE_COOKIE_NAME = "ab-optimize";

export const VALENTINES_BOUQUET_PRICE = 42;

export const EXCLUDED_COUNTRIES = [
  "SG", // Singapore

  "AF", // Afghanistan
  "AD", // Andorra
  "BY", // Belarus
  "CF", // Central African Republic
  "CU", // Cuba
  "FK", // Falkland Islands
  "GQ", // Equatorial Guinea
  "GW", // Guinea-Bissau
  "IR", // Iran
  "JT", // Johnston Island
  "KM", // Comoros
  "SY", // Syria
  "KI", // Kiribati
  "YE", // Yemen Arab Republic
  "KP", // North Korea
  "YT", // Mayotte Island
  "MM", // Myanmar
  "NR", // Nauru
  "NU", // Niue
  "RU", // Russia
  "SH", // St. Helena
  "PM", // St.Pierre and Miquelon
  "ST", // Sao Tome and Prinicpe
  "SB", // Solomon Islands
  "SO", // Somalia
  "SD", // Sudan
  "TJ", // Tajikistan
  "TK", // Tokelu Islands
  "TV", // Tuvalu
  "UA", // Ukraine
  "VE", // Venezuela - FedEx unable to have a fixed set of cities for Venezuela
  "WF", // Wake Islands
];

export const ITEM_CATEGORIES = [
  { value: "mobiles", text: "Mobile Phones" },
  { value: "tablets", text: "Mobile Tablets" },
  { value: "computers", text: "Computers" },
  { value: "cameras", text: "Cameras" },
  { value: "accessories", text: "Cables" },
  // { value: "accessoriesbattery", text: "Accessories (Battery)" },
  { value: "headphones", text: "Headphones & Earphones" },
  { value: "healthbeauty", text: "Beauty & Skincare" },
  { value: "fashion", text: "Clothes" },
  { value: "watches", text: "Watches" },
  { value: "jewelry", text: "Jewelry" },
  { value: "petsaccessories", text: "Pets Accessories" },
  // { value: "dryfoodsupplements", text: "Dry Food & Supplements" }
  { value: "dryfood", text: "Dry Foods" },
  { value: "supplements", text: "Supplements" },
  { value: "homeappliances", text: "Home Appliances" },
  { value: "homedecor", text: "Paintings" },
  { value: "toys", text: "Toys" },
  { value: "sports", text: "Exercise Equipment" },
  { value: "handbags", text: "Handbags" },
  { value: "audiovideo", text: "Audio Video" },
  { value: "documents", text: "Documents & Photos" },
  { value: "gaming", text: "Games & Consoles" },
  // { value: "bookscollectibles", text: "Books" }
  { value: "books", text: "Books" },
];

export const HS_CODE_MAP = {
  mobiles: "851712",
  tablets: "847130",
  computers: "847130",
  cameras: "900659",
  accessories: "854420",
  headphones: "851830",
  healthbeauty: "330410",
  fashion: "630900",
  watches: "910211",
  jewelry: "711790",
  petsaccessories: "420100",
  dryfood: "190590",
  supplements: "293621",
  homeappliances: "851629",
  homedecor: "590190",
  toys: "950300",
  sports: "950631", // refers to golf clubs
  handbags: "420221",
  audiovideo: "852349",
  documents: "491199",
  gaming: "950450",
  books: "490199",
};

export const GIFT_CARD_CONSTANTS = {
  MIN_AMOUNT: 20,
  MAX_NAME_LENGTH: 50,
  MAX_MESSAGE_LENGTH: 200,
  PRESET_AMOUNTS: [50, 100, 300],
  MONTHS_TO_EXPIRY: 12,
};

export const GIFT_CARD_EMAIL_COPY = {
  TERMS_AND_CONDITIONS: [
    "Redeemable for 1 shipment on justship.sg",
    `One time-use only, good for ${GIFT_CARD_CONSTANTS.MONTHS_TO_EXPIRY} months from date of issue`,
    "No returns, refunds on gift card",
  ],
  HOW_TO_REDEEM: [
    {
      text: "Sign up for an account with JustShip at",
      urlType: "REGISTER_URL",
    },
    {
      text: "Click on the gift card above or use this link:",
      urlType: "REDEEM_URL",
    },
    {
      text: "Fill up the shipment form as prompted and enjoy fuss-free international shipping!",
    },
  ],
};

export const getGiftCardUrls = (discountCode) => ({
  REGISTER_URL: `${process.env.NEXT_PUBLIC_HOST}/register`,
  REDEEM_URL: `${process.env.NEXT_PUBLIC_HOST}/create-shipment?discount=${discountCode}`,
});

export const BOXES = [
  {
    boxName: "N10",
    boxLength: 18,
    boxWidth: 10,
    boxHeight: 13,
    boxVolumetric: 0.47,
  },
  {
    boxName: "Box F",
    boxLength: 23,
    boxWidth: 15,
    boxHeight: 9,
    boxVolumetric: 0.62,
  },
  {
    boxName: "N7",
    boxLength: 24,
    boxWidth: 14,
    boxHeight: 17,
    boxVolumetric: 1.14,
  },
  {
    boxName: "Box G",
    boxLength: 26,
    boxWidth: 26,
    boxHeight: 9,
    boxVolumetric: 1.21,
  },
  {
    boxName: "N6",
    boxLength: 27,
    boxWidth: 16,
    boxHeight: 19,
    boxVolumetric: 1.64,
  },
  {
    boxName: "Fedex Box S",
    boxLength: 28,
    boxWidth: 5,
    boxHeight: 33,
    boxVolumetric: 0.92,
  },
  {
    boxName: "N5",
    boxLength: 30,
    boxWidth: 18,
    boxHeight: 20,
    boxVolumetric: 2.16,
  },
  {
    boxName: "Fedex Box M",
    boxLength: 30,
    boxWidth: 6,
    boxHeight: 34,
    boxVolumetric: 1.22,
  },
  {
    boxName: "Box B",
    boxLength: 33,
    boxWidth: 24,
    boxHeight: 31,
    boxVolumetric: 4.91,
  },
  {
    boxName: "Box H",
    boxLength: 33,
    boxWidth: 44,
    boxHeight: 11,
    boxVolumetric: 3.21,
  },
  {
    boxName: "Box I",
    boxLength: 44,
    boxWidth: 39,
    boxHeight: 10,
    boxVolumetric: 3.43,
  },
  {
    boxName: "Fedex Box L",
    boxLength: 36,
    boxWidth: 9,
    boxHeight: 49,
    boxVolumetric: 3.18,
  },
  {
    boxName: "Box C",
    boxLength: 42,
    boxWidth: 26,
    boxHeight: 32,
    boxVolumetric: 6.98,
  },
  {
    boxName: "Box SP",
    boxLength: 61,
    boxWidth: 41,
    boxHeight: 10,
    boxVolumetric: 5.0,
  },
  {
    boxName: "Box A",
    boxLength: 49,
    boxWidth: 34,
    boxHeight: 36,
    boxVolumetric: 12.0,
  },
  {
    boxName: "Box E",
    boxLength: 56,
    boxWidth: 43,
    boxHeight: 35,
    boxVolumetric: 16.86,
  },
  {
    boxName: "Box D",
    boxLength: 43,
    boxWidth: 40,
    boxHeight: 65,
    boxVolumetric: 22.36,
  },
  {
    boxName: "Box MP",
    boxLength: 80,
    boxWidth: 61,
    boxHeight: 9,
    boxVolumetric: 8.78,
  },
  {
    boxName: "Box LP",
    boxLength: 81,
    boxWidth: 101,
    boxHeight: 10,
    boxVolumetric: 16.36,
  },
  {
    boxName: "Box XLP",
    boxLength: 96,
    boxWidth: 115,
    boxHeight: 10,
    boxVolumetric: 22.08,
  },
  {
    boxName: "Box J",
    boxLength: 61,
    boxWidth: 120,
    boxHeight: 42,
    boxVolumetric: 61.49,
  },
];
