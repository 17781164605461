import { isNumber, isNullOrUndefined } from "@/utils/types";

export const deepGet = (obj, key, { raiseErrOnInvalidPath = true } = {}) => {
  let cur = obj;
  const paths = key.replaceAll("[", ".[").split(".");
  for (let path of paths) {
    if (path.startsWith("[") && path.endsWith("]")) path = path.slice(1, -1);
    cur = cur?.[path];

    if (cur === undefined && raiseErrOnInvalidPath)
      throw new Error(`Path "${path}" doesn't exist in object`);
  }

  return cur;
};

/**
 * Set a value using a path key in a nested object.
 */
export const deepSet = (
  obj,
  key,
  value,
  { raiseErrOnInvalidPath = true } = {}
) => {
  const lastDotIndex = key.lastIndexOf(".");
  const pathsWithoutLast = key.substring(0, lastDotIndex);
  const lastPath = key.substring(lastDotIndex + 1, key.length);
  let parentObj = obj;
  if (pathsWithoutLast)
    parentObj = deepGet(obj, pathsWithoutLast, { raiseErrOnInvalidPath });

  parentObj[lastPath] = value;
};

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const deepFreeze = (obj) => {
  Object.keys(obj).forEach((property) => {
    if (typeof obj[property] === "object" && !Object.isFrozen(obj[property]))
      deepFreeze(obj[property]);
  });
  return Object.freeze(obj);
};

export const deepEqual = (object1, object2, options = {}) => {
  const { numberTolerance = 0, ignoredKeys = null } = options;
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (ignoredKeys && ignoredKeys.includes(key)) continue;

    const val1 = object1[key];
    const val2 = object2[key];

    if (isNumber(val1) && isNumber(val2)) {
      const num1 = Number(val1);
      const num2 = Number(val2);
      if (Math.abs(num1 - num2) > numberTolerance) return false;
      continue;
    }

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!arraysEqual(val1, val2, options)) return false;
      continue;
    }

    if (isObject(val1) && isObject(val2)) {
      if (!deepEqual(val1, val2, options)) return false;
      continue;
    }

    if (val1 !== val2) return false;
  }

  return true;
};

export const isObject = (value) => {
  return value != null && !(value instanceof Date) && typeof value === "object";
};

/**
 * Merge the `source` object into `target` object, recursively.
 * @param {object} target
 * @param {object} source
 * @param {object} options
 * @param {boolean=} options.mergeItemsInArrays
 * @returns {object}
 */
export const deepMerge = (
  target,
  source,
  { mergeItemsInArrays = false } = {}
) => {
  if (target === null || target === undefined) return source;
  if (source === null || source === undefined) return target;
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  const newTarget = deepClone(target);

  for (const key of Object.keys(source)) {
    const targetValue = newTarget[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      if (mergeItemsInArrays) {
        const maxLength = Math.max(targetValue.length, sourceValue.length);
        const newValues = [];
        for (let i = 0; i < maxLength; i++) {
          newValues.push(
            deepMerge(targetValue[i], sourceValue[i], { mergeItemsInArrays })
          );
        }
        newTarget[key] = newValues;
      } else newTarget[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      newTarget[key] = deepMerge(targetValue, sourceValue, {
        mergeItemsInArrays,
      });
    } else {
      newTarget[key] = sourceValue;
    }
  }

  return newTarget;
};

/**
 * Ensure an array has length `size`. If shorter, fill the missing values with `fillValue`.
 * `replaceCallback` will overwrite `fillValue` values, if provided.
 * @param {any[]} originalArray
 * @param {object} options
 * @param {number} options.size
 * @param {any} options.fillValue
 * @param {any} options.filterCallback
 * @param {any} options.replaceCallback
 */
export const toFixedSizedArray = (originalArray, options = {}) => {
  const {
    size,
    fillValue = null,
    filterCallback = null,
    replaceCallback = null,
  } = options;
  const fillValueCallback =
    typeof fillValue === "function" ? fillValue : () => fillValue;
  let fixedSizedArr = deepClone(originalArray);

  fixedSizedArr = fixedSizedArr.map((item) => {
    if (filterCallback && !filterCallback(item)) {
      return fillValueCallback(item);
    }

    return item ? item : fillValueCallback(item);
  });

  if (fixedSizedArr.length < size) {
    fixedSizedArr.push(
      ...Array.from(new Array(size - fixedSizedArr.length), fillValueCallback)
    );
  } else if (fixedSizedArr.length > size) {
    fixedSizedArr = fixedSizedArr.slice(0, size);
  }
  if (replaceCallback) fixedSizedArr = fixedSizedArr.map(replaceCallback);

  return fixedSizedArr;
};

/**
 * Return the items that exists in array1 but not array2.
 * @param {any[]} array1
 * @param {any[]} array2
 */
export const differenceBetweenArrays = (array1, array2) => {
  const set2 = new Set(array2);
  const difference = array1.filter((element) => !set2.has(element));
  return difference;
};

export const arraysEqual = (a, b, options) =>
  a.length === b.length &&
  a.every((element, index) => {
    const first = element;
    const second = b[index];
    if (isObject(first) && isObject(second))
      return deepEqual(first, second, options);
    return first === second;
  });

export const flatten = (arr) => {
  const flattenArr = [];
  for (const item of arr) {
    if (Array.isArray(item)) flattenArr.push(...flatten(item));
    else flattenArr.push(item);
  }
  return flattenArr;
};

export const uniq = (arr, callbackFn = null) => {
  const result = [];
  const seen = new Set();

  for (const item of arr) {
    const uniqueIdentifier = callbackFn ? callbackFn(item) : item;
    if (seen.has(uniqueIdentifier)) continue;

    seen.add(uniqueIdentifier);
    result.push(item);
  }

  return result;
};

/*
 * Remove all empty values from an object.
 * @param {object} obj
 * @returns {object}
 */
export const removeEmptyValues = (obj) => {
  if (!obj) return {};

  const newObj = {};
  for (const key in obj) {
    if (isNullOrUndefined(obj[key])) continue;

    newObj[key] = obj[key];
  }
  return newObj;
};
